import React, { useEffect } from "react"
import Styles from "../styles/cf_supporter_list.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Image from "../components/image";
import { BACK_BUTTON_STR } from "../constant/const"
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";


const CfSupporterListPage = () => {

    const headerOption = {
        headerTitle: <span style={ { fontSize: "1rem" } }>クラウドファンディング支援者一覧</span>,
        leftIcon: BACK_BUTTON_STR,
    }

    const isLoggedIn = useIsLoggedIn()

    useEffect( () => {
        document.documentElement.scrollTop = 0
    }, [] )

    return (
        <Layout
            headerOption={ headerOption }
            footerHide={ typeof window !== "undefined" ? !( isLoggedIn ) : true }>
            <>
                <SEO title="クラウドファンディング支援者一覧" />
                <div className={ Styles.container }>
                    <h1>クラウドファンディング支援者一覧</h1>
                    <p>
                        <a href='https://camp-fire.jp/v2/projects/152303'>
                            ClubCloudのスマホアプリ化クラウドファンディングプロジェクト
                        </a>
                        において、このページへの掲載リターンを選んでくださった支援者様の一覧です。<br />
                        お陰様で、76名もの方から、総額506,500円のご支援をいただきました。<br />
                        本当にありがとうございます。
                    </p>
                    <ul className={ Styles.supporter_list }>
                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>大阪大学体操部(OUGC) 様</span>
                            </p>
                            <p>
                                { `大阪大学体操部は、男女共に月･水･土の週3回、豊中キャンパスにて練習を行っています。\n大学から体操競技を始める選手や、中学･高校から競技を続けている経験者が集い、目標に向かって効率的な練習を試行錯誤しています。\n2019年4月より、チームとしてClub Cloudを導入しました。\n練習内容の相互アドバイス、個人の成長記録、演技の記録などに利用しており、技術の伝承と蓄積に役立っています。` }
                            </p>
                            <br />
                            <p>
                                <a href="http://ougc.wp.xdomain.jp/wp-content/uploads/2017/11/index.html">http://ougc.wp.xdomain.jp/wp-content/uploads/2017/11/index.html</a>
                            </p>
                            <div style={ { marginTop: 8, marginLeft: "5%" } }>
                                <Image filename="阪大体操部写真.jpg" />
                            </div>
                        </li>
                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>https://www.nekojarashi.com/ 様</span>
                            </p>
                            <p>
                                <a href="https://www.nekojarashi.com/">
                                    https://www.nekojarashi.com/
                                </a>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>アルファスタント 様</span>
                            </p>
                            <p>
                                <a href="www.alpha-stunts.com">
                                    www.alpha-stunts.com
                                </a>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>武田??('ω')?? 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>徳田大輔 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>西村彰仁 様</span>
                            </p>
                            <p>
                                <a href="https://relyontrip.com/registration">
                                    https://relyontrip.com/registration
                                </a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/relyon_trip/">
                                    https://www.instagram.com/relyon_trip/
                                </a>
                            </p>
                            <p>
                                こんなアプリが欲しかった！！応援してます！！
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>凌駕！ 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>Acrobat & Action Studio Ac2t 代表 及川雄也 様</span>
                            </p>
                            <p>
                                <a href="https://acrobataction.wixsite.com/studio-act">
                                    https://acrobataction.wixsite.com/studio-act
                                </a>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>doiken23 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>kaity 様</span>
                            </p>
                            <p>
                                <a href="https://mobile.twitter.com/imabayashikaito">
                                    https://mobile.twitter.com/imabayashikaito
                                </a>
                            </p>
                            <p>
                                欲しかった動画サービス！スポーツ界でどんどん使われるアプリ・サービスになってほしい！応援してます！
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>momopapa 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>赤沢 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>甲斐 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                            <p>
                                あと1年早ければ大学剣道部でも導入したかった！\nもうコーチも引退してしまったからハードルも上がってるけど、特に指導者の少ないトレーニング環境には非常に効果があると思います。
                            </p>
                        </li>





                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>武部 様</span>
                            </p>
                            <p>
                                このアプリが多くの人に活用されますように！
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>のりこ 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>きねぶち 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>斎藤自快 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>櫻澤 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>迫 様</span>
                            </p>
                            <p>
                                応援しています！頑張ってください！
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>椎名桂子 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>たかけん@レッドインパルス 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>高橋淳 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>竹中啓太 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>手塚 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>二井雄大 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>森 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>Hobbit_Kotaro 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>u++ 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>vorotamoroz 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                        </li>

                        <li>
                            <p className={ Styles.name_and_affiliation }>
                                <span>-- 様</span>
                            </p>
                        </li>
                    </ul>
                </div>
            </>
        </Layout>
    )
}

export default CfSupporterListPage
